<template>
  <div :class="getRentalStatusClass">
    <span>{{ getRentalStatusText(status) }}</span>
    <i :class="icon" />
  </div>
</template>

<script>
export default {
  name: 'StatusPillButton',
  components: {},
  props: {
    status: {
      type: String,
      required: true,
      validator(val) {
        let whitelist = [
          'REQUESTED',
          'ACCEPTED',
          'REJECTED',
          'CANCELLED',
          'TRIP_COMPLETED',
          'DELIVERED',
        ]
        return whitelist.includes(val)
      },
    },
    icon: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      colors: {
        REQUESTED: 'orange',
        ACCEPTED: 'blue',
        REJECTED: 'blush',
        CANCELLED: 'red',
        TRIP_COMPLETED: 'green',
        DELIVERED: 'purple',
      },
      texts: {
        REQUESTED: 'Pending',
        ACCEPTED: 'Accepted',
        REJECTED: 'Rejected',
        CANCELLED: 'Cancelled',
        TRIP_COMPLETED: 'Completed',
        DELIVERED: 'On Trip',
      },
    }
  },
  computed: {
    getRentalStatusClass() {
      const color = this.getRentalStatusColor(this.status)
      return `rental-status-pill-button ${color}`
    },
  },
  methods: {
    getRentalStatusColor() {
      return this.colors[this.status] || 'blue'
    },
    getRentalStatusText() {
      return this.texts[this.status] || '--'
    },
  },
}
</script>

<style lang="scss" scoped>
@import './$rental-status.scss';
</style>
